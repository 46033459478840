import {
  EyeIcon,
  GiftIcon,
  HandThumbUpIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import Link from "next/link";
import { useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { Comment } from "../api";
import {
  CollaborationThread,
  Group,
  GroupAutocompleteResult,
} from "../api/types";
import { useCurrentUser } from "./AuthProvider";
import AutocompleteMultiselect from "./Select/AutocompleteMultiselect";
import Avatar from "./Avatar";
import CommentInput from "./CommentInput";
import { ConfirmationModal } from "./ConfirmationModal";
import Tooltip from "./Tooltip";

interface CommentListProps {
  isLoading: boolean;
  comments: Comment[];
  deleteComment: (id: number) => void;
  editComment: (comment: Comment) => void;
  canLoadMore: boolean;
  loadMore: () => void;
  updateVote?: (commentId: number) => void;
  updateReward?: (commentId: number) => void;
  thread?: CollaborationThread | null;
  showVisibility?: boolean;
}

export default function CommentList({
  isLoading,
  comments,
  canLoadMore,
  loadMore,
  deleteComment,
  editComment,
  updateVote,
  updateReward,
  thread,
  showVisibility = false,
}: CommentListProps) {
  const [edit, setEdit] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<number>(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [deletedCommentId, setDeletedCommentId] = useState<number>(0);
  const [currentComment, setCurrentComment] = useState("");
  const [currentGroups, setCurrentGroups] = useState<GroupAutocompleteResult[]>(
    []
  );
  const [currentUsers, setCurrentUsers] = useState<GroupAutocompleteResult[]>(
    []
  );

  const [displayVisibility, setDisplayVisibility] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>();
  const { user: currentUser } = useCurrentUser();

  const url_mapping: { [id: string]: string } = {
    meetingnote: "meeting_notes",
    opportunity: "opportunity",
  };

  const cancelHandler = (commentId: number, commentText: string) => {
    const divEl = window.document.getElementById(`p_${commentId}`);
    if (divEl) {
      divEl.innerText = commentText;
    }
    setEditingCommentId(0);
    setEdit(false);
    setDisplayVisibility(false);
  };

  const editingHandler = (comment: Comment) => {
    const groups: GroupAutocompleteResult[] = [];
    const users: GroupAutocompleteResult[] = [];
    let isPublicItem: boolean;
    if (comment.access_policy) {
      setIsVisible(true);
      isPublicItem = true;
    } else {
      isPublicItem = false;
      setIsVisible(false);
    }
    (comment.access_policy?.groups || []).map((group) => {
      const gr: GroupAutocompleteResult = {
        label: group.name,
        value: group.id,
      };
      groups.push(gr);
    });
    (comment.access_policy?.users || []).map((user) => {
      const gr: GroupAutocompleteResult = {
        label: user.name,
        value: user.id,
      };
      users.push(gr);
    });
    setCurrentComment(comment.text);
    setEditingCommentId(comment.id);
    setCurrentGroups(groups);
    setCurrentUsers(users);
    setEdit(true);
  };

  const saveHandler = (comment: Comment, currentComment: string) => {
    const groups: Group[] = [];
    const users: Group[] = [];

    (currentGroups || []).map((group) => {
      const gr: Group = {
        name: group.label,
        id: group.value,
      };
      groups.push(gr);
    });
    (currentUsers || []).map((user) => {
      const ur: Group = {
        name: user.label as any,
        id: user.value,
      };
      users.push(ur);
    });

    comment.text = currentComment;
    if (comment.access_policy && comment.access_policy.groups) {
      comment.access_policy.groups = isVisible === true ? groups : [];
    }

    if (comment.access_policy && comment.access_policy.users) {
      comment.access_policy.users = isVisible === true ? (users as any) : [];
    }
    let access_policy_1 = {
      groups: groups,
      users: users as any,
    };
    if (!comment.access_policy) {
      comment.access_policy = access_policy_1;
    }
    editComment(comment);
    setEditingCommentId(0);
    setEdit(false);
  };

  return (
    <div className={`mt-6 divide-gray-300 divide-y text-xs`}>
      {comments.map((comment, index) => {
        const isCurrentEditingComment = editingCommentId === comment.id;
        const viewStatus =
          comment.access_policy?.groups &&
          comment.access_policy?.groups.length > 0 &&
          comment.access_policy?.users &&
          comment.access_policy?.users.length > 1
            ? comment.access_policy?.groups
                .map((group) => group.name)
                .join(", ") + " & Selected Users"
            : comment.access_policy?.groups &&
              comment.access_policy?.groups.length > 0
            ? comment.access_policy?.groups
                .map((group) => group.name)
                .join(", ")
            : comment.access_policy?.users &&
              comment.access_policy?.users.length > 0
            ? "Selected Users"
            : "all";
        return (
          <div
            key={comment.id}
            className={`flex flex-row w-full ${
              index !== 0 ? "pt-5" : ""
            } relative`}
          >
            <div className="flex flex-col ml-2 flex-1">
              <div className={`flex flex-wrap justify-between flex-col`}>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center">
                    <div>
                      <Avatar
                        className="bg-gray-300"
                        name={comment.user.name}
                        size={26}
                        url={comment.user.image_url}
                      />
                    </div>
                    <div className="flex flex-col ml-2">
                      <span className="font-bold">{comment.user.name} </span>
                      <ReactTimeAgo
                        date={new Date(comment.created_at)}
                        locale="en-US"
                      />
                    </div>
                  </div>
                  <div className="items-center">
                    <span className="flex flex-row font-xs text-gray-500 mr-2 items-center">
                      <div className="group relative">
                        {!thread && (
                          <EyeIcon className="h-4 w-4  stroke-gray-500 mr-1 cursor-pointer hover:fill-blue-500 hover:stroke-white" />
                        )}
                        <div className="absolute border bg-black text-white rounded-md shadow-md invisible group-hover:visible text-xs p-2 top-[20px] right-[5px] z-10 w-max">
                          <div className="text-2xs">
                            {viewStatus.toLowerCase()[0].toUpperCase() +
                              viewStatus.toLowerCase().substring(1)}
                          </div>
                          {comment.access_policy?.users &&
                            comment.access_policy?.users.length > 0 &&
                            comment.access_policy?.users.map(
                              (user) =>
                                user.id !== comment.user.id && (
                                  <Link
                                    key={user.id}
                                    href={`/people/${user.id}`}
                                    passHref
                                    legacyBehavior
                                  >
                                    <div className="relative flex mt-2 owner cursor-pointer">
                                      <Avatar
                                        key={user.id}
                                        className="bg-gray-300 ml-1"
                                        name={user.name}
                                        size={24}
                                        url={user.image_url}
                                      />
                                      <span className="absolute bg-black text-white top-6 justify-center py-1 ownername border rounded-md px-2 hidden text-2xs">
                                        {user.name}
                                      </span>
                                    </div>
                                  </Link>
                                )
                            )}
                        </div>
                      </div>

                      {thread && updateVote && updateReward && comment.votes && (
                        <>
                          <button
                            className={`flex gap-x-1 w-10 h-6 rounded-xl bg-gray-200 items-center justify-center ml-5 ${
                              comment.votes.filter(
                                (vote) => vote.owner.id === currentUser.id
                              ).length > 0
                                ? "bg-blue-500 text-white"
                                : ""
                            }`}
                            onClick={() => updateVote(comment.id)}
                          >
                            <HandThumbUpIcon className="w-3 h-3" />

                            {comment.votes.length}
                          </button>
                          {thread.owner.id === currentUser.id && (
                            <GiftIcon
                              className={`w-4 h-4 cursor-pointer ml-2 ${
                                comment.reward
                                  ? "fill-yellow-500 stroke-white"
                                  : "fill-gray-400 stroke-white animate-pulse"
                              }`}
                              onClick={() => updateReward(comment.id)}
                            />
                          )}
                        </>
                      )}

                      {currentUser.id === comment.user.id && (
                        <div className="flex flex-row items-center">
                          {!isCurrentEditingComment && (
                            <PencilIcon
                              key={`edit_${comment.id}`}
                              className="w-4 h-4 stroke-blue-menu mr-1 cursor-pointer"
                              onClick={() => editingHandler(comment)}
                            />
                          )}
                          <TrashIcon
                            className="w-4 h-4 text-red-500 cursor-pointer"
                            onClick={() => {
                              setConfirmDelete(true);
                              setDeletedCommentId(comment.id);
                            }}
                          />
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                {comment?.source && comment?.source?.model !== "organization" && (
                  <span className="my-1 text-blue-menu font-medium whitespace-pre-line text-xs">
                    <Link
                      href={`/${url_mapping[comment.source.model]}/${
                        comment.source.id
                      }`}
                      legacyBehavior
                    >
                      {comment.source.name}
                    </Link>
                  </span>
                )}
                <div
                  className={`my-2 ${
                    edit &&
                    editingCommentId === comment.id &&
                    "border border-gray-300 rounded-xl p-3"
                  }`}
                >
                  <div className="flex flex-col gap-y-2">
                    {!isCurrentEditingComment && (
                      <CommentInput disabled={true} value={comment.text} />
                    )}
                    {edit && isCurrentEditingComment && (
                      <CommentInput
                        isEdit={edit && isCurrentEditingComment}
                        value={currentComment}
                        onChange={(value) => setCurrentComment(value)}
                      />
                    )}
                    {edit && isCurrentEditingComment && (
                      <div className="flex flex-row w-full gap-x-2 justify-end my-2">
                        {showVisibility && (
                          <div
                            className={`flex flex-row items-center justify-center gap-x-1 text-xs  ${
                              displayVisibility
                                ? "border-[1px] border-blue-menu bg-blue-menu"
                                : "border-[1px] border-blue-menu"
                            } rounded-full w-32 py-1 cursor-pointer`}
                            onClick={() =>
                              setDisplayVisibility(!displayVisibility)
                            }
                          >
                            <EyeIcon
                              className={`h-4 w-4 ${
                                displayVisibility
                                  ? "fill-blue-menu text-white"
                                  : "fill-white text-blue-menu"
                              } `}
                            />
                            <div
                              className={`text-2xs ${
                                displayVisibility
                                  ? "text-white"
                                  : "text-blue-menu"
                              }`}
                            >
                              {displayVisibility
                                ? "Hide Visibility"
                                : "Show Visibility"}
                            </div>
                          </div>
                        )}
                        <button
                          className="btn-secondary"
                          onClick={() =>
                            cancelHandler(comment.id, comment.text)
                          }
                        >
                          Cancel
                        </button>
                        <button
                          className="btn-primary"
                          onClick={() => saveHandler(comment, currentComment)}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center">
                    {edit &&
                      editingCommentId === comment.id &&
                      showVisibility &&
                      displayVisibility && (
                        <div className="my-3">
                          <div className="flex items-center flex-col">
                            <div className="flex flex-row w-full items-center font-medium mb-1">
                              Visibility
                              <Tooltip
                                title="What is Visibility?"
                                content="Visibility allows you to have an option of deciding who can view the activity. All user activity by default is only visible to user groups or funds that you belong to."
                              />
                            </div>
                            <div className="flex flex-row items-center w-full">
                              <input
                                checked={!isVisible}
                                type="radio"
                                id="public"
                                value="public"
                                onChange={() => {
                                  setIsVisible(false);
                                }}
                              />
                              <label
                                className="ml-3 block font-xs text-gray-700 "
                                htmlFor="public"
                              >
                                Public
                              </label>
                              <input
                                checked={isVisible}
                                type="radio"
                                id="only"
                                value="Specific groups/users"
                                className="ml-6"
                                onChange={() => {
                                  setIsVisible(true);
                                }}
                              />
                              <label
                                className="ml-3 block font-xs text-gray-700 "
                                htmlFor="only"
                              >
                                Specific groups/users
                              </label>
                            </div>
                          </div>
                          {isVisible === true && (
                            <>
                              <label
                                className="flex mt-2.5 mb-2"
                                htmlFor="groupsField"
                              >
                                <div className="font-medium">
                                  Specific groups/users
                                </div>
                                <div className="h-4 w-4 text-red-600">*</div>
                              </label>
                              <div className="flex flex-col space-y-2 text-xs">
                                <div className="text-gray-700 w-full">
                                  <AutocompleteMultiselect
                                    autocompleteEndpoint={
                                      "/api/users/autocomplete/allgroups"
                                    }
                                    selected={currentGroups}
                                    onChange={(e: any) => setCurrentGroups(e)}
                                  />
                                </div>
                                <div className="text-gray-700 w-full">
                                  <AutocompleteMultiselect
                                    autocompleteEndpoint={`/api/users/autocomplete/users`}
                                    selected={currentUsers as any}
                                    onChange={(e: any) => setCurrentUsers(e)}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <ConfirmationModal
        open={confirmDelete && !!deletedCommentId}
        title={`Are you sure to delete this comment?`}
        subtitle="This action cannot be undone"
        succeed={deleted}
        succeedMessage={`Comment deleted.`}
        processing={isLoading}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setConfirmDelete(false);
          setDeletedCommentId(0);
          setDeleted(false);
        }}
        onSubmit={() => {
          deleteComment(deletedCommentId);
          setDeleted(true);
        }}
      />
      {comments.length > 0 && canLoadMore && (
        <button
          disabled={isLoading}
          type="button"
          className="px-4 py-2 rounded-full bg-blue-menu disabled:bg-gray-300 text-white disabled:cursor-not-allowed"
          onClick={() => loadMore()}
        >
          {isLoading && (
            <svg
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          )}
          {isLoading ? "Loading..." : "Load more comments"}
        </button>
      )}
    </div>
  );
}
